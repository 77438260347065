// TODO: Import reset css
@import "shared.scss";
@import "fonts.scss";

$maxWidth: 600px;

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html {
  background-color: $background_color;
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  line-height: $line_height;
  font-family: 'Larsseit', sans-serif;
  font-size: $font_size;
  font-weight: 300;
  color: #fff;
  // Keeps any channel titles that are super long words
  // (e.g. "No.ONE.EVER.Really.Dies" by Jay Curry) from
  // extending super wide and breaking the page layout
  overflow-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;

  &.modal-open {
    height: 100vh;
    width: 100vw;
    overflow-y: hidden;
    position: fixed;
  }
}

#modal {
  position: relative;
  z-index: 9999;
}

a, a:visited, a:hover, a:active {
  color: #fff;
}

hr {
  border-color: $border_color;
  border-style: solid;
  border-bottom-width: 0;
  margin: 1rem 0;
}

p {
  margin: 0;

  &:not(:first-of-type) {
    margin-top: 1rem;
  }

  @media (max-width: $maxWidth) {
    font-size: 12px;
  }
}

strong {
  font-weight: bold;
  font-size: 1.05em;
}

h1, h2, h3, h4 {
  margin: 0.25rem 0;
  line-height: 1.2;
}

h1 {
  font-size: 36px;
  font-weight: 900;

  @media (max-width: $maxWidth) {
    font-size: 24px;
  }
}

h2 {
  font-size: 30px;
  font-weight: bold;

  @media (max-width: $maxWidth) {
    font-size: 20px;
  }
}

h3 {
  font-size: 24px;
  font-weight: bold;

  @media (max-width: $maxWidth) {
    font-size: 18px;
  }
}

h4 {
  font-size: 18px;
  font-weight: 200;
  line-height: 1.4;

  @media (max-width: $maxWidth) {
    font-size: 16px;
  }
}

/* Forms */

input, button {
  font-size: 100%;
  font-family: inherit;
  font-weight: 300;
}

button {
  text-decoration: underline;
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
}

label {
  font-weight: bold;
  font-size: 16px;

  &[for] {
    cursor: pointer;
  }
}

input[type="checkbox"] {
  transform: scale(1.4);
  margin-right: 1rem;
}

// Overrides for react-tooltip library, if we use it
// .__react_component_tooltip {
//   border-radius: 0 !important;
//   font-size: inherit !important;
//   padding: inherit !important;

//   &.show {
//     opacity: inherit !important;
//   }

//   &:before, &:after {
//     border: none !important;
//   }
// }
